<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="8">
          <h3>
            Deal List {{ listType }}
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="8">
          <h3>Deal List {{ listType }}</h3>
          <br />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <br />
          <v-data-table
            @click:row="handleClick"
            :headers="headers"
            :items="dealList"
            :search="search"
            :items-per-page="-1"
          >
            <template v-slot:[`item.public_name`]="{ item }">
              <div style="min-width: 150px">
                {{ item.public_name }}
              </div>
            </template>
            <template v-slot:[`item.province`]="{ item }">
              {{ item.province | capitalize }}</template
            >
            <template v-slot:[`item.amountRequested`]="{ item }">
              {{ item.amountRequested | currency }}</template
            >
            <template v-slot:[`item.status`]="{ item }">
              {{ item.status | capitalize_first_letter }}</template
            >
            <template v-slot:[`item.renewal`]="{ item }">
              {{ item.renewal | yes_no }}</template
            >

            <template v-slot:[`item.ltv`]="{ item }">
              <div>
                {{ item.ltv ? `${item.ltv}%` : "" }}
              </div>
            </template>

            <template v-slot:[`item.amount_requested`]="{ item }">
              {{ item.amount_requested | formatPrice }}
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              {{ item.createdAt | formatDate }}</template
            >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";
import filters from "@/plugins/filters";

export default {
  name: "DealRead",
  props: ["dealId", "view_type"],
  data() {
    return {
      listType: null,
      search: "",
      headers: [
        {
          text: "Public Name",
          align: "start",
          filterable: true,
          value: "public_name",
        },
        {
          text: "Internal Name",
          align: "start",
          filterable: true,
          value: "internal_name",
        },

        //  {
        //   text: "Request",
        //   align: "start",
        //   filterable: true,
        //   value: "deal_summary.amount_requested",
        // },
        //  {
        //   text: "City",
        //   align: "start",
        //   filterable: true,
        //   value: "deal_summary.city",
        // },
        {
          text: "Province",
          align: "start",
          filterable: true,
          value: "province",
        },
        {
          text: "Status",
          align: "start",
          filterable: true,
          value: "status",
        },
        {
          text: "Renewal",
          align: "start",
          filterable: true,
          value: "renewal",
        },

        {
          text: "Deal Summary Status",
          align: "start",
          filterable: true,
          value: "deal_summary_status",
        },

        {
          text: "Customer Name",
          align: "start",
          filterable: true,
          value: "customer_name",
        },

        {
          text: "Property Location",
          align: "start",
          filterable: true,
          value: "property_address",
        },

        {
          text: "LTV",
          align: "start",
          filterable: true,
          value: "ltv",
        },

        {
          text: "Mortgage Request",
          align: "start",
          filterable: true,
          value: "amount_requested",
        },

        {
          text: "Created",
          align: "start",
          filterable: true,
          value: "createdAt",
        },

        // {
        //   text: "City",
        //   value: "city",
        // },
        // {
        //   text: "Province",
        //   value: "province",
        // },
        // {
        //   text: "LTV",
        //   value: "ltv",
        // },
        // {
        //   text: "Requested",
        //   value: "amountRequested",
        // },
        // {
        //   text: "Status",
        //   value: "status",
        // },
      ],
      componentLoading: true,
      error: "",
      dealList: [],
      active_deal_summaries: [],
    };
  },
  filters: {
    ...filters,
  },
  methods: {
    handleClick(value) {
      if (this.view_type === "create_deal") {
        this.$emit("dealId", value.id);
      } else {
        this.$router.push(`/dashboard/admin/deal/read/${value.id}`);
      }
    },
  },
  watch: {
    "$route.params.status"(val) {
      // call the method which loads your initial state
      this.find();
    },
  },
  mounted() {
    // console.log('route query', this.$route.query)
    let dealListType = null;

    if (this.view_type === 'create_deal') {
      this.listType = "- All";
      dealListType = "all";
    } else {
      if (this.$route.query.status == "all") {
        this.listType = "- All";
        dealListType = "all";
      } else if (this.$route.query.status == "In Progress") {
        this.listType = "- In Progress";
        dealListType = "In Progress";
      } else if (this.$route.query.status == "Funded") {
        this.listType = "- Funded";
        dealListType = "Funded";
      } else if (this.$route.query.status == "Closed") {
        this.listType = "- Closed";
        dealListType = "Closed";
      } else if (this.$route.query.status == "Not Completed / Archive") {
        this.listType = "- Archived";
        dealListType = "Not Completed / Archive";
      } else if (
        this.$route.query.status == "Active Deal Summaries"
      ) {
        this.listType = "- Active Deal Summaries";
        dealListType = "Active Deal Summaries";
      }
    }

    API()
      .get(`/api/internal-admin/getAllDeals?status=${dealListType}`)
      .then((res) => {
        // console.log(res);
        this.dealList = res.data;

        this.dealList.forEach((deal) => {
          if (deal.deal_summaries.length === 0) {
            deal.deal_summary_status = "Inactive";
          }

          deal.deal_summaries.forEach((el) => {
            if (el.status === "active") {
              deal.deal_summary_status = "Active";
            } else {
              deal.deal_summary_status = "Inactive";
            }
          });

          // customer name
          deal.int_custs.forEach((el) => {
            if (el.main_applicant) {
              deal.customer_name =
                el.customer &&
                el.customer.first_name + " " + el.customer.last_name;
            }
          });

          // property address
          deal.int_props.forEach((el) => {
            if (el.subject) {
              deal.property_address =
                el.prop.address.city + ", " + el.prop.address.province;
            }
          });

          // ltv
          deal.deal_summaries.forEach((el) => {
            deal.ltv = el.loan_to_value;
          });

          // amount requested
          deal.deal_summaries.forEach((el) => {
            deal.amount_requested = el.amount_requested;
          });
        });

        this.componentLoading = false;
      })
      .catch((e) => {
        console.log("error");
        console.log(e);
      });
  },
};
</script>

<style></style>
